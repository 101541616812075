"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _Pagination = _interopRequireDefault(require("./Pagination"));
var _PaginationFirst = _interopRequireDefault(require("./PaginationFirst"));
var _PaginationItem = _interopRequireDefault(require("./PaginationItem"));
var _PaginationLast = _interopRequireDefault(require("./PaginationLast"));
var _PaginationNext = _interopRequireDefault(require("./PaginationNext"));
var _PaginationPrevious = _interopRequireDefault(require("./PaginationPrevious"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }
_Pagination["default"].First = _PaginationFirst["default"];
_Pagination["default"].Item = _PaginationItem["default"];
_Pagination["default"].Last = _PaginationLast["default"];
_Pagination["default"].Next = _PaginationNext["default"];
_Pagination["default"].Previous = _PaginationPrevious["default"];
var _default = _Pagination["default"];
exports["default"] = _default;