"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _Animation = _interopRequireDefault(require("./Animation"));
var _FadeInAnimation = _interopRequireDefault(require("./FadeInAnimation"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }
_Animation["default"].FadeIn = _FadeInAnimation["default"];
var _default = _Animation["default"];
exports["default"] = _default;