"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _Card = _interopRequireDefault(require("./Card"));
var _CardBody = _interopRequireDefault(require("./CardBody"));
var _CardHeader = _interopRequireDefault(require("./CardHeader"));
var _CardSubtitle = _interopRequireDefault(require("./CardSubtitle"));
var _CardTitle = _interopRequireDefault(require("./CardTitle"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }
_Card["default"].Body = _CardBody["default"];
_Card["default"].Title = _CardTitle["default"];
_Card["default"].Subtitle = _CardSubtitle["default"];
_Card["default"].Header = _CardHeader["default"];
var _default = _Card["default"];
exports["default"] = _default;