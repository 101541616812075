"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _native = _interopRequireDefault(require("styled-components/native"));
var _Row = _interopRequireDefault(require("../Row"));
var _responsive = require("../utilities/responsive");
var _templateObject;
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }
function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }
var ModalFooter = (0, _native["default"])(_Row["default"])(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  padding: ", "px;\n  justify-content: center;\n  width: 100%;\n  border-top-width: 1px;\n  border-color: #dee2e6;\n"])), (0, _responsive.remToDp)(1));
var _default = ModalFooter;
exports["default"] = _default;