"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _Table = _interopRequireDefault(require("./Table"));
var _Td = _interopRequireDefault(require("./Td"));
var _Th = _interopRequireDefault(require("./Th"));
var _Tr = _interopRequireDefault(require("./Tr"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }
_Table["default"].th = _Th["default"];
_Table["default"].td = _Td["default"];
_Table["default"].tr = _Tr["default"];
var _default = _Table["default"];
exports["default"] = _default;