"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _Modal = _interopRequireDefault(require("./Modal"));
var _ModalBody = _interopRequireDefault(require("./ModalBody"));
var _ModalFooter = _interopRequireDefault(require("./ModalFooter"));
var _ModalHeader = _interopRequireDefault(require("./ModalHeader"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }
_Modal["default"].Header = _ModalHeader["default"];
_Modal["default"].Body = _ModalBody["default"];
_Modal["default"].Footer = _ModalFooter["default"];
var _default = _Modal["default"];
exports["default"] = _default;