"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _native = _interopRequireDefault(require("styled-components/native"));
var _responsive = require("../utilities/responsive");
var _Div = _interopRequireDefault(require("../Div"));
var _templateObject;
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }
function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }
var CardHeader = (0, _native["default"])(_Div["default"])(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  background-color: rgba(33, 37, 41, 0.03);\n  padding: ", "px ", "px;\n  margin-bottom: 0px;\n  border-bottom-width: 1px;\n  border-color: rgba(0, 0, 0, 0.175);\n"])), (0, _responsive.remToDp)(0.5), (0, _responsive.remToDp)(1));
var _default = CardHeader;
exports["default"] = _default;