"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _Drawer = _interopRequireDefault(require("./Drawer"));
var _DrawerBody = _interopRequireDefault(require("./DrawerBody"));
var _DrawerHeader = _interopRequireDefault(require("./DrawerHeader"));
var _DrawerToggle = _interopRequireDefault(require("./DrawerToggle"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }
_Drawer["default"].Header = _DrawerHeader["default"];
_Drawer["default"].Body = _DrawerBody["default"];
_Drawer["default"].Toggle = _DrawerToggle["default"];
var _default = _Drawer["default"];
exports["default"] = _default;