"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _Accordion = _interopRequireDefault(require("./Accordion"));
var _AccordionBody = _interopRequireDefault(require("./AccordionBody"));
var _AccordionHeader = _interopRequireDefault(require("./AccordionHeader"));
var _AccordionItem = _interopRequireDefault(require("./AccordionItem"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }
_Accordion["default"].Item = _AccordionItem["default"];
_Accordion["default"].Header = _AccordionHeader["default"];
_Accordion["default"].Body = _AccordionBody["default"];
var _default = _Accordion["default"];
exports["default"] = _default;