"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _native = _interopRequireDefault(require("styled-components/native"));
var _Td = _interopRequireDefault(require("./Td"));
var _templateObject;
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }
function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }
var Th = (0, _native["default"])(_Td["default"])(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  font-weight: bold;\n"])));
var _default = Th;
exports["default"] = _default;