"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _Button = _interopRequireDefault(require("./Button"));
var _CloseButton = _interopRequireDefault(require("./CloseButton"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }
_Button["default"].Close = _CloseButton["default"];
var _default = _Button["default"];
exports["default"] = _default;